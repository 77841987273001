import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

import {
  BasePricing,
  CustomerType,
  FeeType,
  PlanVariant,
  Price,
  RawPlan,
  Region,
} from '../plan/planContext.types';
import { VehicleContext } from '../vehicle/VehicleContext';
import { Authentication } from '../../../hooks/useAuthentication/useAuthentication';

export enum ChargePointRegistrationStatus {
  // Subscription cannot register for ChargePoint service
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  // Subscription can register for ChargePoint service
  AVAILABLE = 'AVAILABLE',
  // Subscription is pending registration for ChargePoint service
  ACTIVATION_PENDING = 'ACTIVATION_PENDING',
  // Subscription is registered for ChargePoint service
  REGISTERED = 'REGISTERED',
}

export enum TerminationReason {
  INVOICE_NOT_PAID = 'INVOICE_NOT_PAID',
}

type InactiveChargePointRegistration = {
  status:
    | ChargePointRegistrationStatus.AVAILABLE
    | ChargePointRegistrationStatus.ACTIVATION_PENDING
    | ChargePointRegistrationStatus.NOT_AVAILABLE;
};

type ActiveChargePointRegistration = {
  email: string;
  userId: string;
  status: ChargePointRegistrationStatus;
};

export type ChargePointRegistration =
  | ActiveChargePointRegistration
  | InactiveChargePointRegistration;

export interface Subscription {
  id: string;
  country: string;
  region: Region;
  vin: string;
  /**
   * ISO 8601 date
   */
  inclusivePeriodActiveUntil?: string;
  /**
   * ISO 8601 date
   */
  startDate: string;
  /**
   * ISO 8601 date
   */
  endDate?: string;
  /**
   * ISO 8601 date
   */
  renewalDate?: string;
  /**
   * ISO 8601 date
   */
  nextPossibleEffectiveDate?: string;
  isExpired: boolean;
  isTerminated: boolean;
  terminationReason: TerminationReason;
  isSuspended: boolean;
  currentOrLastPhase: Phase;
  nextCustomerRelevantPhase?: Phase;
  invoiceAddress: {
    company: string;
    firstname: string;
    lastname: string;
    street: string;
    streetNo: string;
    zip: string;
    city: string;
    vatId?: string;
  };
  paymentReference: string;
  customerType: CustomerType;
  chargePointRegistration: ChargePointRegistration;
}

export interface Phase {
  /**
   * ISO 8601 date
   */
  startDate: string;
  /**
   * ISO 8601 date
   */
  endDate?: string;
  /**
   * ISO 8601 date
   */
  renewalDate?: string;
  plan: RawPlan;
}

export type InclusivePeriod = null | {
  freeUntil: Date;
  afterwards: null | {
    rawPlan: RawPlan;
    marketplace: string;
  };
};

export enum NetworkStatus {
  Loading = 'LOADING',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

interface SubscriptionContextProps {
  vehicle: VehicleContext;
  auth: Authentication;
  dummy: boolean;
}

/**
 * Fetches and returns the most relevant subscription, may be expired
 */
export class SubscriptionContext {
  public network: NetworkStatus = NetworkStatus.Loading;
  public subscription: Subscription | null;
  public hasSuspendedContracts: boolean = false;

  private readonly auth: Authentication;
  private readonly vehicle: VehicleContext;

  constructor(props: SubscriptionContextProps) {
    makeAutoObservable(this);
    this.vehicle = props.vehicle;
    this.auth = props.auth;

    if (props.dummy) {
      return;
    }
    this.fetchData();
  }

  public fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions?vin=${this.vehicle.vin}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.auth.token}`,
            'apikey': this.auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );
      const list = (await response.json()) as Subscription[];

      // Sort all subscriptions, newer go to top
      const sorted = list
        .filter((listItem) => listItem.vin === this.vehicle.vin)
        .sort((a, b) => {
          return (
            new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
          );
        });

      // Get the first subscription matching the vin
      const subscription = sorted.find((item) => {
        return item.vin === this.vehicle.vin;
      });

      this.subscription = subscription || null;
      this.network = NetworkStatus.Success;

      if (subscription) {
        this.checkHasSuspendedContracts();
      }

      // Not a single subscription, call the vehicleContext to check ownership
      if (this.subscription === null) {
        this.vehicle.checkOwnership();
      }
    } catch (e: unknown) {
      console.error(e);
      this.network = NetworkStatus.Error;
    }
  };

  private checkHasSuspendedContracts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.auth.token}`,
            'apikey': this.auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );
      const list = (await response.json()) as Subscription[];

      const hasSuspendedContracts = list.some((item) => item.isSuspended);

      this.hasSuspendedContracts = hasSuspendedContracts;
    } catch (e: unknown) {
      console.error(e);
    }
  };

  /**
   * Terminate the current contract <br/>
   * Returns success
   */
  public terminate = async (): Promise<boolean> => {
    if (!this.subscription) {
      return false;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/${this.subscription?.id}/terminate`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.auth.token}`,
            'apikey': this.auth.apiKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        return false;
      }

      this.subscription = await response.json();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  // Ugly as hell, needs a refactor
  public updateSubscription = (subscription: Subscription) => {
    this.subscription = subscription;
  };

  public getCurrencyCode(): string | undefined {
    if (!this.subscription) {
      return;
    }

    if (this.subscription.region === Region.CA) {
      return 'CAD';
    }

    if (this.subscription.region === Region.US) {
      return 'USD';
    }

    // Take currency code of base price if existing
    const base = this.subscription.currentOrLastPhase.plan.options[
      this.subscription.country
    ].find((item) => {
      return item.feeType === FeeType.BASE;
    }) as BasePricing | undefined;

    if (base) {
      return base.pricingModel.price.grossAmount.currency;
    }

    // Just try our best at this point
    for (const item of this.subscription.currentOrLastPhase.plan.options[
      this.subscription.country
    ]) {
      const unknown = item as {
        pricingModel?: {
          priceTiers?: { price: Price }[];
        };
      };

      if (
        unknown.pricingModel?.priceTiers &&
        unknown.pricingModel.priceTiers[0]
      ) {
        return unknown.pricingModel.priceTiers[0].price.grossAmount.currency;
      }
    }

    return 'EUR';
  }

  /**
   * Returns display meta for the inclusive period
   */
  public getInclusivePeriod = (): InclusivePeriod => {
    if (!this.subscription) {
      return null;
    }

    // No follow-up, display nothing
    if (!this.subscription.nextCustomerRelevantPhase) {
      return null;
    }

    // Current plan is not inclusive, display nothing
    if (
      ![
        PlanVariant.V1_PREMIUM_INCLUSIVE,
        PlanVariant.V2_PREMIUM_INCLUSIVE,
      ].includes(this.subscription.currentOrLastPhase.plan.variant)
    ) {
      return null;
    }

    // Follow-up NA V2_BASIC is free, display nothing
    if (
      [Region.CA, Region.US].includes(this.subscription.region) &&
      this.subscription.nextCustomerRelevantPhase.plan.variant ===
        PlanVariant.V2_BASIC
    ) {
      return null;
    }

    // Follow-up is also inclusive (V1_PREMIUM_INCLUSIVE upgrade to V2_PREMIUM_INCLUSIVE)
    if (
      [
        PlanVariant.V1_PREMIUM_INCLUSIVE,
        PlanVariant.V2_PREMIUM_INCLUSIVE,
      ].includes(this.subscription.nextCustomerRelevantPhase.plan.variant)
    ) {
      // Type-safety only, should not happen
      if (!this.subscription.nextCustomerRelevantPhase.endDate) {
        return null;
      }
      return {
        freeUntil: new Date(
          this.subscription.nextCustomerRelevantPhase.endDate,
        ),
        afterwards: null,
      };
    }

    // Type-safety only, should not happen
    if (!this.subscription.inclusivePeriodActiveUntil) {
      return null;
    }

    return {
      freeUntil: new Date(this.subscription.inclusivePeriodActiveUntil),
      afterwards: {
        rawPlan: this.subscription.nextCustomerRelevantPhase.plan,
        marketplace: this.subscription.country,
      },
    };
  };
}

export const subscriptionDummyContext = new SubscriptionContext({
  auth: {} as Authentication,
  vehicle: { vin: '', marketplace: '' } as VehicleContext,
  dummy: true,
});

export const subscriptionContext = createContext<SubscriptionContext>(
  subscriptionDummyContext,
);
