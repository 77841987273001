import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'confirmation.failure.title',
    defaultMessage: 'Activation failed',
    description: 'Title of the failure page',
  },
  titleSanctionCheck: {
    id: 'confirmation.failure.titleSanctionCheck',
    defaultMessage: 'Ativation not possible',
    description: 'Title of the failure page if failed on sanction check',
  },
  subtitle: {
    id: 'confirmation.failure.subtitle',
    defaultMessage: 'Here’s what you can do',
    description: 'Subtitle of the failure page',
  },
  subtitleSanctionCheck: {
    id: 'confirmation.failure.subtitleSanctionCheck',
    defaultMessage:
      'Unfortunately, our check confirmed that you are not eligible for this service. Please contact our customer support for more details.',
    description: 'Subtitle of the failure page if failed on sanction check',
  },
  help1: {
    id: 'confirmation.failure.help1',
    defaultMessage:
      'Unfortunately, the Porsche Charging Service activation failed . Please contact our {customerSupport} to resolve this issue or try activating the service again later.',
    description: 'Single item of self service help list',
  },
  customerSupportLinkLabel: {
    id: 'confirmation.failure.customerSupportLinkLabel',
    defaultMessage: 'customer support',
    description: 'Customer support link included in help text',
  },
  action: {
    id: 'confirmation.failure.action',
    defaultMessage: 'Go back to my car',
    description: 'Button with link to porsche profile',
  },
  goBackButtonLabel: {
    id: 'confirmation.failure.goBackButtonLabel',
    defaultMessage: 'Go back',
    description: 'Go back button label',
  },
  goToPorscheIdButtonLabel: {
    id: 'confirmation.failure.goToPorscheIdButtonLabel',
    defaultMessage: 'Go to Porsche ID',
    description: 'Go to Porsche ID button label',
  },
  goToManageButtonLabel: {
    id: 'confirmation.failure.goToManageButtonLabel',
    defaultMessage: 'Go to MyCharging Management',
    description: 'Go to manage subscription button label',
  },
  goToMyPorschePortalButtonLabel: {
    id: 'confirmation.failure.goToMyPorschePortal',
    defaultMessage: 'Open {myPorschePortalLinkLabel}',
    description: 'Go to my porsche portal button label',
  },
  myPorschePortalLinkLabel: {
    id: 'confirmation.failure.myPorschePortalLinkLabel',
    defaultMessage: 'My Porsche Portal',
    description: 'Vehicle owner check fail subtitle part 2',
  },
  s111Title: {
    id: 'confirmation.failure.s111Title',
    defaultMessage: 'VAT validation failed',
    description: 'Title for falied vat validation',
  },
  s111Subtitle: {
    id: 'confirmation.failure.s111Subtitle',
    defaultMessage:
      'We couldn’t activate your Porsche Charging Service because the provided VAT ID couldn’t be validated. Double-check that your VAT ID is entered correctly and follows the required format.',
    description: 'Subtitle for falied vat validation',
  },
  s112Title: {
    id: 'confirmation.failure.s112Title',
    defaultMessage: 'Activation denied due to overdue debt',
    description: 'Title for falied suspended status',
  },
  s112Subtitle: {
    id: 'confirmation.failure.s112Subtitle',
    defaultMessage:
      'Your subscription has been terminated because the outstanding payment was not completed in due time. To activate the service again, please ensure the overdue amount is paid in full. Information about open invoices can be checked {s112Here}.',
    description: 'Subtitle for falied suspended status',
  },
  s112Here: {
    id: 'confirmation.failure.s112Here',
    defaultMessage: 'here',
    description: 'Text for link to open invoices, in english "here"',
  },
  s113Title: {
    id: 'confirmation.failure.s113Title',
    defaultMessage: 'Activation not possible',
    description: 'Entitlement check fail title',
  },
  s113Subtitle: {
    id: 'confirmation.failure.s113Subtitle',
    defaultMessage:
      'The selected Porsche Charging Service is not available for your vehicle type.',
    description: 'Entitlement check fail subtitle',
  },
  s114Title: {
    id: 'confirmation.failure.s114Title',
    defaultMessage: 'Vehicle not linked to your account',
    description: 'Vehicle owner check fail title',
  },
  s114Subtitle: {
    id: 'confirmation.failure.s114Subtitle',
    defaultMessage:
      "It looks like this Porsche vehicle isn’t added to your garage, so we can’t create or modify your Charging Service subscription. If you're the owner, please confirm your ownership in the My Porsche App by adding the vehicle to your profile.",
    description: 'Vehicle owner check fail subtitle',
  },
  s114SubtitleTwo: {
    id: 'confirmation.failure.s114SubtitleTwo',
    defaultMessage:
      'Open {myPorschePortalLinkLabel} to download the app and get started. Once your vehicle is added, you’ll be able to manage your subscription.',
    description: 'Vehicle owner check fail subtitle part 2',
  },
  s116Title: {
    id: 'confirmation.failure.s116Title',
    defaultMessage: 'Activation not possible',
    description: 'Sanction check fail title',
  },
  s116Subtitle: {
    id: 'confirmation.failure.s116Subtitle',
    defaultMessage:
      "Unfortunately, we couldn't activate your Porsche Charging Service because your account didn't pass the required verification check. Please contact our {customerSupport} for further assistance.",
    description: 'Sanction check fail subtitle',
  },
  s117Title: {
    id: 'confirmation.failure.s117Title',
    defaultMessage: 'Terms & Conditions expired',
    description: 'TAC check failed title',
  },
  s117Subtitle: {
    id: 'confirmation.failure.s117Subtitle',
    defaultMessage:
      'The acceptance period for these terms and conditions has ended, so this action is no longer available. You can still manage your charging subscription and check for updates in MyCharging Management.',
    description: 'TAC check failed subtitle',
  },
  s118Title: {
    id: 'confirmation.failure.s118Title',
    defaultMessage: 'Activation not possible',
    description: 'Invoice address check failed title',
  },
  s118Subtitle: {
    id: 'confirmation.failure.s118Subtitle',
    defaultMessage:
      'The invoice address you entered isn’t registered with your Porsche ID profile, so we can’t process your request. You can go back to the previous step and enter a new address or update your registered address in your Porsche ID profile before trying again.',
    description: 'Invoice address check failed subtitle',
  },
  s119Title: {
    id: 'confirmation.failure.s119Title',
    defaultMessage: 'Action not available for this subscription',
    description: 'State check failed title',
  },
  s119Subtitle: {
    id: 'confirmation.failure.s119Subtitle',
    defaultMessage:
      'It looks like your subscription is in a state that doesn’t allow this action. Check your subscription status in MyCharging Management to see available options.',
    description: 'State check failed subtitle',
  },
  s121Title: {
    id: 'confirmation.failure.s121Title',
    defaultMessage: 'Subscription already active for this vehicle',
    description: 'Subscription already active check failed title',
  },
  s121Subtitle: {
    id: 'confirmation.failure.s121Subtitle',
    defaultMessage:
      'This vehicle already has an active Porsche Charging Service subscription. You can manage or review your subscription details in MyCharging Management. ',
    description: 'TAC not existing check failed subtitle',
  },
  s122Title: {
    id: 'confirmation.failure.s122Title',
    defaultMessage: 'Activation not possible',
    description: 'TAC not existing check failed title',
  },
  s122Subtitle: {
    id: 'confirmation.failure.s122Subtitle',
    defaultMessage:
      'Unfortunately, we couldn’t activate your Porsche Charging Service because the required terms and conditions are not available for your country. Please contact our {customerSupport} for further assistance.',
    description: 'Subscription already active check failed subtitle',
  },
  e111Title: {
    id: 'confirmation.failure.e111Title',
    defaultMessage: 'Something went wrong',
    description: 'Subscription already active check failed title',
  },
  e111Subtitle: {
    id: 'confirmation.failure.e111Subtitle',
    defaultMessage:
      'We encountered an issue while processing your request. Our team is working on resolving this issue. Please try again in a few minutes. If the problem persists, contact our {customerSupport} for further assistance.',
    description: 'Subscription already active check failed subtitle',
  },
  g111Title: {
    id: 'confirmation.failure.g111Title',
    defaultMessage: 'Access not authorized',
    description: 'Authorization check failed title',
  },
  g111Subtitle: {
    id: 'confirmation.failure.g111Subtitle',
    defaultMessage:
      'You don’t have the required permissions to perform this action. This might be because your Porsche profile is not linked to the data you are trying to access. Check if your profile is correctly linked in your Porsche ID profile.',
    description: 'Authorization check failed subtitle',
  },
  g112Title: {
    id: 'confirmation.failure.g112Title',
    defaultMessage: 'Something went wrong',
    description: 'Unknown check failed title',
  },
  g112Subtitle: {
    id: 'confirmation.failure.g112Subtitle',
    defaultMessage:
      'We encountered an issue while processing your request. Our team is working on resolving this issue. Please try again in a few minutes. If the problem persists, contact our {customerSupport} for further assistance.',
    description: 'Unknown check failed subtitle',
  },
  p111Title: {
    id: 'confirmation.failure.p111Title',
    defaultMessage: 'Payment method not recognized',
    description: 'Payment check failed title',
  },
  p111Subtitle: {
    id: 'confirmation.failure.p111Subtitle',
    defaultMessage:
      'The payment method provided doesn’t exist or isn’t linked to your account, so we can’t process the payment. Please add a new payment method and try again.',
    description: 'Payment check failed subtitle',
  },
});
