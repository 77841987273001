import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { SliderLayout } from 'components/layout/slider/SliderLayout';
import { useRoutes } from 'hooks/useRoutes';
import backgroundTariff from 'media/backgroundTariff.jpg';
import backgroundWelcome from 'media/backgroundWelcome.png';
import {
  checkoutDetailsStorageKey,
  completedStepsStorageKey,
  stepsValueStorageKey,
} from 'pages/activation/useCheckout';
import { SelectPage } from 'pages/tariff/select/SelectPage';
import { WelcomePage } from 'pages/tariff/welcome/WelcomePage';
import React, { useContext, useEffect } from 'react';
import { Routes } from 'router/routes';

import { Entitlement } from '../../components/context/vehicle/VehicleContext';

export const TariffPage: React.FunctionComponent = () => {
  const context = useContext(vehicleContext);
  const routes = useRoutes();

  useEffect(() => {
    localStorage.removeItem(checkoutDetailsStorageKey);
    localStorage.removeItem(completedStepsStorageKey);
    localStorage.removeItem(stepsValueStorageKey);
  }, []);

  const panels = [
    {
      background: backgroundWelcome,
      element: <WelcomePage link={routes(Routes.TariffSelect)} />,
      link: routes(Routes.TariffWelcome),
    },
    {
      background: backgroundTariff,
      element: <SelectPage />,
      link: routes(Routes.TariffSelect),
    },
  ];

  if (
    !(
      context.entitlement === Entitlement.Inclusive ||
      context.entitlement === Entitlement.Exclusive
    )
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <SliderLayout panels={panels} />
    </React.Fragment>
  );
};
