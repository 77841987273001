// import { } from 'react';
import { useRoutes } from 'hooks/useRoutes';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Routes } from 'router/routes';

import { messages } from './failurePage.messages';

type ErrorCodeContent = {
  title: string;
  subtitle: string | JSX.Element | (string | JSX.Element)[];
  subtitleTwo?: string | JSX.Element | (string | JSX.Element)[];
  primaryButton: string;
  primaryButtonAction: () => void;
  secondaryButton?: string;
  secondaryButtonAction?: () => void;
};

const uuidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

export const useErrorCodes = () => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const generateRoute = useRoutes();
  const navigate = useNavigate();

  const errorCode = searchParams.get('errorCode');
  const requestId = searchParams.get('requestId');
  const { vin, marketplace, locale } = useParams();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleGoToPorscheIdButtonClick = () => {
    window.open(
      `${process.env.REACT_APP_PORSCHE_ID_MY_PROFILE_FRONTEND_URL}/${marketplace}/${locale}`,
      '_blank',
    );
  };

  const handleGoToManageButtonClick = () => {
    navigate(generateRoute(Routes.SubscriptionManage));
  };

  const handleGoToMyPorschePortalButtonClick = () => {
    window.open(
      `${process.env.REACT_APP_MY_PORSCHE_URL}/app/${marketplace}/${locale}`,
      '_blank',
    );
  };

  const defaultContentWithotErrorCode: ErrorCodeContent = {
    title: intl.formatMessage(messages.g112Title),
    subtitle: intl.formatMessage(messages.g112Subtitle, {
      customerSupport: (
        <a
          href={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/contact`}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage(messages.customerSupportLinkLabel)}
        </a>
      ),
    }),
    primaryButton: intl.formatMessage(messages.goBackButtonLabel),
    primaryButtonAction: handleBackButtonClick,
  };

  const errorCodeContentMap: { [key: string]: ErrorCodeContent } = {
    S111: {
      title: intl.formatMessage(messages.s111Title),
      subtitle: intl.formatMessage(messages.s111Subtitle),

      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
    S112: {
      title: intl.formatMessage(messages.s112Title),
      subtitle: intl.formatMessage(messages.s112Subtitle, {
        s112Here: (
          <a
            href={`${process.env.REACT_APP_OHS_FRONTEND_URL}/${marketplace}/${locale}/account/orders?type=charging`}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(messages.s112Here)}
          </a>
        ),
      }),
      primaryButton: intl.formatMessage(messages.goToManageButtonLabel),
      primaryButtonAction: handleGoToManageButtonClick,
    },
    S113: {
      title: intl.formatMessage(messages.s113Title),
      subtitle: intl.formatMessage(messages.s113Subtitle),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
    S114: {
      title: intl.formatMessage(messages.s114Title),
      subtitle: intl.formatMessage(messages.s114Subtitle),
      subtitleTwo: intl.formatMessage(messages.s114SubtitleTwo, {
        myPorschePortalLinkLabel: (
          <a
            href={`${process.env.REACT_APP_MY_PORSCHE_URL}/app/${marketplace}/${locale}`}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(messages.myPorschePortalLinkLabel)}
          </a>
        ),
      }),
      primaryButton: intl.formatMessage(
        messages.goToMyPorschePortalButtonLabel,
        {
          myPorschePortalLinkLabel: intl.formatMessage(
            messages.myPorschePortalLinkLabel,
          ),
        },
      ),
      primaryButtonAction: handleGoToMyPorschePortalButtonClick,
    },
    S115: {
      title: intl.formatMessage(messages.s111Title),
      subtitle: intl.formatMessage(messages.s111Subtitle),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
    S116: {
      title: intl.formatMessage(messages.s116Title),
      subtitle: intl.formatMessage(messages.s116Subtitle, {
        customerSupport: (
          <a
            href={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/contact`}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(messages.customerSupportLinkLabel)}
          </a>
        ),
      }),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
    S117: {
      title: intl.formatMessage(messages.s117Title),
      subtitle: intl.formatMessage(messages.s117Subtitle),
      primaryButton: intl.formatMessage(messages.goToManageButtonLabel),
      primaryButtonAction: handleGoToManageButtonClick,
    },
    S118: {
      title: intl.formatMessage(messages.s118Title),
      subtitle: intl.formatMessage(messages.s118Subtitle),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleGoToManageButtonClick,
      secondaryButton: intl.formatMessage(messages.goToPorscheIdButtonLabel),
      secondaryButtonAction: handleGoToPorscheIdButtonClick,
    },
    S119: {
      title: intl.formatMessage(messages.s119Title),
      subtitle: intl.formatMessage(messages.s119Subtitle),
      primaryButton: intl.formatMessage(messages.goToManageButtonLabel),
      primaryButtonAction: handleGoToManageButtonClick,
    },
    S121: {
      title: intl.formatMessage(messages.s121Title),
      subtitle: intl.formatMessage(messages.s121Subtitle),

      primaryButton: intl.formatMessage(messages.goToManageButtonLabel),
      primaryButtonAction: handleGoToManageButtonClick,
    },
    S122: {
      title: intl.formatMessage(messages.s122Title),
      subtitle: intl.formatMessage(messages.s122Subtitle, {
        customerSupport: (
          <a
            href={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/contact`}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(messages.customerSupportLinkLabel)}
          </a>
        ),
      }),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
    E111: {
      title: intl.formatMessage(messages.e111Title),
      subtitle: intl.formatMessage(messages.e111Subtitle, {
        customerSupport: (
          <a
            href={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/contact`}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(messages.customerSupportLinkLabel)}
          </a>
        ),
      }),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
    G111: {
      title: intl.formatMessage(messages.g111Title),
      subtitle: intl.formatMessage(messages.g111Subtitle),
      primaryButton: intl.formatMessage(messages.goToPorscheIdButtonLabel),
      primaryButtonAction: handleGoToPorscheIdButtonClick,
    },
    G112: {
      title: intl.formatMessage(messages.g112Title),
      subtitle: intl.formatMessage(messages.g112Subtitle, {
        customerSupport: (
          <a
            href={`${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/contact`}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(messages.customerSupportLinkLabel)}
          </a>
        ),
      }),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
    P111: {
      title: intl.formatMessage(messages.p111Title),
      subtitle: intl.formatMessage(messages.p111Subtitle),
      primaryButton: intl.formatMessage(messages.goBackButtonLabel),
      primaryButtonAction: handleBackButtonClick,
    },
  };
  const shouldShowDefaultContent =
    !errorCode ||
    !/^[a-zA-Z0-9]{1,6}$/.test(errorCode) ||
    !uuidRegex.test(requestId || '');

  if (shouldShowDefaultContent) {
    return {
      errorCodeContent: defaultContentWithotErrorCode,
      errorCode,
      requestId,
    };
  }

  const errorCodeContent =
    errorCodeContentMap[errorCode || 'G112'] || defaultContentWithotErrorCode;

  return { errorCodeContent, errorCode, requestId };
};
