import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';
import { useFetchAddress } from 'hooks/useFetchAddress';
import { defaultLocale } from 'hooks/useParams';
import { Address } from 'pages/activation/components/AddressPreview';
import {
  getNormalizedLocale,
  TAddressChangeEventPayload,
} from 'pages/activation/components/AddressWidget';
import { BusinessData } from 'pages/activation/components/BusinessVerification';
import { isVatCheckRequired } from 'pages/activation/useCheckout';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  fetchBusinessVerification,
  TBusinessVerificationStruct,
} from './utils';

export const useAddress = ({
  onVatVerification,
}: {
  onVatVerification: (isVerified: boolean) => void;
}) => {
  const authentication = useAuthentication();
  const widgetApiKey = authentication.apiKey;
  const authorizationToken = authentication.token;
  const ciamId = authentication.ciamId;
  const { locale, marketplace } = useParams();

  const [address, setAddress] = useState<TAddressChangeEventPayload>();
  const [fullAddress, setFullAddress] = useState<Address>();

  const [hasDoneVerification, setHasDoneVerification] = useState(false);
  const [hasVerificatioErrored, setHasVerificatioErrored] = useState(false);
  const { fetchAddressById } = useFetchAddress();
  const [isLoading, setIsLoading] = useState(false);

  const [newBusinessVerificationData, setNewBusinessVerificationData] =
    useState<Partial<TBusinessVerificationStruct>>({
      legalName: undefined,
      vat: undefined,
      country: undefined,
    });

  useEffect(() => {
    setHasDoneVerification(false);
    setHasVerificatioErrored(false);
    setNewBusinessVerificationData({
      legalName: undefined,
      vat: undefined,
      country: undefined,
    });
    setFullAddress(undefined);
  }, [address]);

  useEffect(() => {
    if (address) {
      fetchAddressById(
        typeof address === 'string' ? address : address.selectedAddressId,
      ).then((response): void => {
        if (!response || !isVatCheckRequired(response)) {
          return;
        }

        fetchBusinessVerification({
          legalName: response.companyName1 ?? '',
          vat: response.vatId ?? '',
          country: response.country ?? '',
          authorizationToken,
          ciamId,
        }).then((isVerified) => {
          if (isVerified) {
            // returning early here instead of setting the state
            // would remove the "Verified" pill that shows under the verification form
            setHasDoneVerification(true);
            setHasVerificatioErrored(false);
            onVatVerification(true);

            return;
          }

          onVatVerification(false);

          setFullAddress(response);
        });
      });
    }
  }, [address, widgetApiKey, authorizationToken, fetchAddressById]);

  const requestBusinessVerification =
    fullAddress &&
    isVatCheckRequired(fullAddress) &&
    !(hasDoneVerification && !hasVerificatioErrored);

  const handleBusinessVerificationSubmit = async () => {
    setIsLoading(true);
    if (fullAddress) {
      const payload = {
        legalName:
          newBusinessVerificationData.legalName ??
          fullAddress.companyName1 ??
          '',
        vat: newBusinessVerificationData.vat ?? fullAddress.vatId ?? '',
        country:
          newBusinessVerificationData.country ?? fullAddress.country ?? '',
      };

      const isVerified = await fetchBusinessVerification({
        ...payload,
        authorizationToken,
        ciamId,
      });

      setHasDoneVerification(true);
      setHasVerificatioErrored(!isVerified);

      if (isVerified) {
        const normalizedLocale = getNormalizedLocale(locale ?? defaultLocale);

        await fetch(
          `${process.env.REACT_APP_PORSCHE_PROFILES_API_URL}/${marketplace}/${normalizedLocale}/address/${fullAddress.addressId}`,
          {
            method: 'PUT',
            headers: {
              'apikey': authentication.apiKey,
              'Authorization': `Bearer ${authentication.token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...fullAddress,
              vatId: payload.vat.replaceAll(/[^a-z0-9]/gi, ''),
              companyName1: payload.legalName,
            }),
          },
        )
          .then((response) => response.json())
          .catch(() => ({}));
      }

      onVatVerification(isVerified);
    }
    setIsLoading(false);
  };

  const handleBusinessChange = async (data: BusinessData) => {
    setNewBusinessVerificationData((prev) => ({
      ...prev,
      [data.key]: data.value,
    }));
  };

  const handleAddressChange = (newAddress: TAddressChangeEventPayload) => {
    if (!newAddress) {
      return;
    }

    if (address !== newAddress) {
      return setAddress(newAddress);
    }

    fetchAddressById(newAddress.toString()).then((response): void => {
      if (!response || !isVatCheckRequired(response)) {
        return;
      }

      fetchBusinessVerification({
        legalName: response.companyName1 ?? '',
        vat: response.vatId ?? '',
        country: response.country ?? '',
        authorizationToken,
        ciamId,
      }).then((isVerified) => {
        if (isVerified) {
          // returning early here instead of setting the state
          // would remove the "Verified" pill that shows under the verification form
          setHasDoneVerification(true);
          setHasVerificatioErrored(false);
          return;
        }

        setFullAddress(response);
        setHasDoneVerification(false);
        setHasVerificatioErrored(true);
      });
    });
  };

  return {
    hasDoneVerification,
    address,
    handleAddressChange,
    isLoading: isLoading,
    fetchAddressById,
    requestBusinessVerification,
    authentication,
    hasVerificatioErrored,
    fullAddress,
    handleBusinessChange,
    newBusinessVerificationData,
    handleBusinessVerificationSubmit,
  };
};
