import { PButton, PText } from '@porsche-design-system/components-react';
import { FrostedHeading } from 'components/layout/frosted/FrostedHeading';
import { FrostedItem } from 'components/layout/frosted/FrostedItem';
import { FrostedLayout } from 'components/layout/frosted/FrostedLayout';
import { Step, Stepper } from 'components/stepper/Stepper';
import background from 'media/backgroundFailure.jpg';
import style from 'pages/confirmation/failure/failurePage.module.scss';
import { FC } from 'react';

import { useErrorCodes } from './useErrorCodes';

export const FailurePage: FC = () => {
  const { errorCodeContent, errorCode, requestId } = useErrorCodes();

  return (
    <div data-testid={'failurePage'}>
      <Stepper currentStep={Step.Confirmation} />
      <FrostedLayout background={background}>
        <FrostedHeading data-testid="failure-title">
          {errorCodeContent?.title}
        </FrostedHeading>
        {errorCodeContent?.subtitle ? (
          <FrostedItem
            data-testid="failure-subtitle"
            stylingClass={
              !errorCodeContent?.subtitleTwo ? style.failureFrostedItem : ''
            }
          >
            <PText size="medium">{errorCodeContent.subtitle}</PText>
          </FrostedItem>
        ) : null}
        {errorCodeContent?.subtitleTwo ? (
          <FrostedItem
            data-testid="failure-subtitle"
            stylingClass={style.failureFrostedItem}
          >
            <PText size="medium">{errorCodeContent.subtitleTwo}</PText>
          </FrostedItem>
        ) : null}

        <PText color="contrast-medium" className={style.errorCodeText}>
          {errorCode ? errorCode : ''}
          {requestId ? ` ${requestId}` : ''}
        </PText>

        <div>
          <PButton
            className={style.button}
            onClick={errorCodeContent?.primaryButtonAction}
          >
            {errorCodeContent?.primaryButton}
          </PButton>

          {errorCodeContent?.secondaryButton ? (
            <PButton
              variant="secondary"
              className={style.button}
              onClick={errorCodeContent?.secondaryButtonAction}
            >
              {errorCodeContent?.secondaryButton}
            </PButton>
          ) : null}
        </div>
      </FrostedLayout>
    </div>
  );
};
